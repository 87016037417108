import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, FormGroup, FormControl, FormLabel, Alert } from "react-bootstrap";
import "./login.css";

function Login({ history, login }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    async function handleLogin(event) {
        event.preventDefault();
        var userEntry = {
            email: email,
            password: password,
            rememberMe: true
        };

        const response = await (fetch('/api/v1/admin/user/login', {
            method: 'post',
            body: JSON.stringify(userEntry),
            headers: {"Content-Type": "application/json"}
        }).catch(handleError));

        if (response.ok) {
            login(email);
            history.push('/events');
        } else {
            setError(true);
        }
    }

    return (
        <div className="Login">

            <form onSubmit={handleLogin}>
                <FormGroup controlId="email">
                    <FormLabel>Email</FormLabel>
                    <FormControl
                        autoFocus
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="password">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                </FormGroup>

                <Alert show={error} variant={'danger'} onClose={() => setError(false)} dismissible>Invalid username or password</Alert>

                <Button block disabled={!validateForm()} type="submit">
                    Login
                </Button>
            </form>
        </div>
    );
}

export default withRouter(Login);