import React, { useState, useEffect  } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { withRouter } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './NavMenu.css';


function NavMenu({ history, login, logout, setApp, authenticated, applicationId }) {
    const [collapsed, setCollapsed] = useState(false);
    const [applications, setApplications] = useState([]);
    useEffect(() => {
        
        async function populateApplications() {
            const response = await(fetch('/api/v1/admin/applications', {}).catch((e) => { console.log(e); }));

            if (response.ok) {
                const data = await response.json();
                setApplications(data);
                if (!applicationId) {
                    updateSelectedApp(data[0].id);
                }
            } else {
                backoutToLogin();
            }
        }
        if (authenticated) {
            populateApplications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    function backoutToLogin() {
        logout();
        history.push('/login');
    }

    function toggleNavbar() {
        setCollapsed(!collapsed);
    }

    function updateSelectedApp(appId) {
        setApp(appId);
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand onClick={() => { history.push('/events') }}>Virtual Run Admin</NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />

                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        {authenticated ?
                            <>
                                <Button className="ml-2" as="input" type="button" value="Log out" onClick={backoutToLogin} />
                                <ul className="navbar-nav flex-grow">
                                    <Form.Control as="select" custom onChange={(event) => { updateSelectedApp(event.target.value) }} value={applicationId || ""}>
                                            {applications.map(v => (
                                                <option key={v.id} value={v.id}>{v.name}</option>
                                            ))}
                                    </Form.Control>
                                </ul>
                                <Button className="mr-2" variant="info" as="input" type="button" value="Active Events" onClick={() => { history.push('/demo/events')}} />
                                <Button className="mr-2" variant="info" as="input" type="button" value="User Flow" onClick={() => { history.push('/demo/userflow') }} />
                                <Button className="mr-2" variant="info" as="input" type="button" value="Admin" onClick={() => { history.push('/events') }} />
                            </>
                        : <></>}
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default withRouter(NavMenu);