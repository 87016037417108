import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, FormGroup, FormControl, FormLabel, Alert, Form, Table, Image } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import EntryPlanDetails from '../event/entryplandetails';

function DemoUserFlow({ applicationId }) {
    const [regId, setRegId] = useState("");
    const [eventId, setEventId] = useState("");
    const [dob, setDob] = useState(new Date(""));
    const [authKey, setAuthKey] = useState("");
    const [error, setError] = useState(false);
    const [step, setStep] = useState(0);

    const [entryDetails, setEntryDetails] = useState(undefined);

    const [loggedRuns, setLoggedRuns] = useState([]);
    const [newRunDate, setNewRunDate] = useState(new Date());
    const [newRunDistance, setNewRunDistance] = useState(0);
    const [newRunTime, setNewRunTime] = useState(0);


    const [challengeTime, setChallengeTime] = useState(0);
    const [challengeDistance, setChallengeDistance] = useState(0);
    const [challengeComplete, setChallengeComplete] = useState(0);
    const [challengeEvidence, setChallengeEvidence] = useState(0);
    const [challengeGenerateCertificate, setChallengeGenerateCertificate] = useState(0);
    const [challengeSuccess, setChallengeSuccess] = useState(undefined);

    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    async function populateRuns() {
        if (!applicationId) return;
        const response = await (fetch(`/api/v1/evidence/logs?eventId=${eventId}&regId=${regId}`, { headers: { "Content-Type": "application/json", "x-api-key": authKey }}).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            setLoggedRuns(data.data);
        } else {
            setError(`No logs found for eventId - ${eventId}`);
        }
    }

    async function validateEvidenceEntry(event) {
        if (event) { event.preventDefault(); }

        const response = await (fetch(`/api/v1/evidence/entries/details?eventId=${+eventId}&regId=${regId}`, {
            method: 'get',
            headers: { "Content-Type": "application/json", "x-api-key": authKey }
        }).catch(handleError));

        if (response.ok) {
            const resData = await response.json();
            if (resData.success) {
                setEntryDetails(resData.data);
                populateRuns();
                setError(false);
                setStep(1);
            }
        } else {
            setError("error validating registration");
        }
    }

    async function addRun(event) {
        event.preventDefault();
        var payload = {
            eventId: +eventId,
            regId: +regId,
            dob: dob,
            distance: +newRunDistance,
            time: +newRunTime,
            logDate: newRunDate,
            UnitOfMeasure: 0
        };

        const response = await (fetch('/api/v1/evidence/logs', {
            method: 'post',
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json", "x-api-key": authKey }
        }).catch(handleError));
        const data = await response.json();
        if (data.success) {
            setLoggedRuns(prev => [...prev, data.data]);
        } else {
            setError(data.errors);
        }
    }

    async function deleteRun(eventId, regId, logId) {
        const response = await (fetch(`/api/v1/evidence/logs?eventId=${eventId}&regId=${regId}&logId=${logId}`, {
            method: 'delete',
            headers: { "Content-Type": "application/json", "x-api-key": authKey }
        }).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            if (data.success) {
                let loggedRunsNew = loggedRuns.filter(p => p.id !== logId);
                setLoggedRuns(prev => loggedRunsNew);
            } else {
                setError(data.errors);
            }
        } else {
            setError("Error removing log entry.");
        }
    }

    async function completeChallenge(event) {
        event.preventDefault();

        var formData = new FormData();
        formData.append('eventId', +eventId);
        formData.append('RegId', +regId);
        formData.append('Dob', dob.toISOString());
        formData.append('Time', +challengeTime);
        formData.append('Distance', +challengeDistance);
        formData.append('UnitOfMeasure', 0);
        formData.append('GenerateCertificate', challengeGenerateCertificate);
        formData.append('Complete', true);


        if (challengeEvidence) {
            [...challengeEvidence].forEach((e, index) => formData.append('Evidence', challengeEvidence[index]));
        }
        

        const response = await (fetch('/api/v1/evidence/entries', {
            method: 'post',
            body: formData,
            headers: { "x-api-key": authKey}
        }).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            setChallengeSuccess(data.data);
        } else {
            const data = await response.json();
            setError(data.errors);
        }
    }

    const stepDetailEntry = () => {
        return (
            <div className="Login">
                <form onSubmit={validateEvidenceEntry}>

                    <FormGroup>
                        <FormLabel>Auth key (would be in header)</FormLabel>
                        <FormControl
                            autoFocus
                            value={authKey}
                            onChange={e => setAuthKey(e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Registration Id</FormLabel>
                        <FormControl
                            autoFocus
                            value={regId}
                            onChange={e => setRegId(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>EventId</FormLabel>
                        <FormControl
                            autoFocus
                            value={eventId}
                            onChange={e => setEventId(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup style={{ display: "flex", flexDirection: "column" }}>
                        <FormLabel>Date of Birth</FormLabel>
                        <DatePicker dateFormat="dd/MM/yyyy" selected={new Date(dob)} onChange={(date) => { setDob(date); }} />
                    </FormGroup>

                    <Alert show={error} variant={'danger'} transition={false} onClose={() => setError(false)} dismissible>Invalid username or password</Alert>

                    <Button block type="submit">
                        Next
                    </Button>
                </form>
            </div>
        )
    }

    const stepLogEntry = () => {
        return (
            <div>
                <Form onSubmit={(e) => { e.preventDefault(); setError(false); setStep(2) }}>
                    <Form.Row style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
                        <Form.Group style={{ display: "flex", flexDirection: "column" }}>
                            <Form.Label>Date</Form.Label>
                            <DatePicker dateFormat="dd/MM/yyyy" selected={new Date(newRunDate)} onChange={(date) => { setNewRunDate(date); }} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Distance</Form.Label>
                            <Form.Control
                                autoFocus
                                value={newRunDistance}
                                onChange={e => setNewRunDistance(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Time</Form.Label>
                            <Form.Control
                                autoFocus
                                value={newRunTime}
                                onChange={e => setNewRunTime(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label></Form.Label>
                            <Button block onClick={addRun}>
                                Save run
                            </Button>
                        </Form.Group>
                    </Form.Row>
                    <Alert show={error} variant={'danger'} transition={false} onClose={() => setError(false)} dismissible>{error}</Alert>

                    <Table bordered striped responsive size="sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Distance</th>
                                <th>Time</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loggedRuns.map((run, index) =>
                                <tr key={index}>
                                    <td>{run.logDate}</td>
                                    <td>{run.distanceMetric}</td>
                                    <td>{run.time}</td>
                                    <td style={{ textAlign: 'center' }}><i className="fa fa-trash" style={{ color: 'red', cursor: 'pointer'}} onClick={() => deleteRun(eventId, regId, run.id)}></i></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>


                    <Button block type="submit">
                        Complete Challenge
                </Button>
                </Form>
            </div>
        )
    }

    const stepPlanEntry = () => {
        return (
            <div>
                <Form onSubmit={(e) => { e.preventDefault(); setError(false); setStep(2) }}>
                    
                    <EntryPlanDetails authKey={authKey} entryDetail={entryDetails}></EntryPlanDetails>

                    <Button block type="submit">
                        Complete Challenge
                    </Button>
                    
                </Form>
            </div>
        )
    }

    const stepDeclaration = () => {
        return (
            <div>
                <Form onSubmit={completeChallenge}>
                    Complete declaration below to confirm...
                    <Form.Group>
                        <Form.File id="exampleFormControlFile1" label="Example file input" multiple onChange={e => setChallengeEvidence(e.target.files)} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Total Running Time</Form.Label>
                        <Form.Control
                            autoFocus
                            value={challengeTime}
                            onChange={e => setChallengeTime(e.target.value)}
                        />

                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Total Distance Complete</Form.Label>
                        <Form.Control
                            autoFocus
                            value={challengeDistance}
                            onChange={e => setChallengeDistance(e.target.value)}
                        />

                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Generate Certificate (will cause error in remote environment for now)</Form.Label>
                        <Form.Check checked={challengeGenerateCertificate} onChange={e => setChallengeGenerateCertificate(e.target.checked)} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Declaration</Form.Label>
                        <Form.Check checked={challengeComplete} onChange={e => setChallengeComplete(e.target.checked)} />
                    </Form.Group>

                    <Alert show={error} variant={'danger'} transition={false} onClose={() => setError(false)} dismissible>{error}</Alert>

                    <Button block type="submit">
                        Confirm
                    </Button>
                </Form>
            </div>
        );
    }

    if (challengeSuccess) {
        return (
            <>
            <Alert variant={'success'}>Submitted evidence successfully</Alert>
                {challengeSuccess.certificate ? <Image src={challengeSuccess.certificate} fluid /> : <></>}
            </>
        );
    }
    else if (step === 0) return stepDetailEntry();
    else if (step === 1) {return entryDetails.event.eventTypeStr === 'plan' ? stepPlanEntry() : stepLogEntry() }
    else if (step === 2) return stepDeclaration();
}

export default withRouter(DemoUserFlow);