import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter, } from 'react-router-dom'
import { Container } from 'react-bootstrap';

import PrivateRoute from "./components/PrivateRoute";
import NavMenu from "./components/NavMenu";
import Login from './pages/login/login.js';
import Events from './pages/events/events';
import EventEntries from './pages/event/evententries';
import EntryDetails from './pages/event/entrydetails';
import DemoEvents from './pages/demo/demoevents';
import DemoUserFlow from './pages/demo/demouserflow';
import './custom.css'

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("vrUser"));
    const [applicationId, setApplicationId] = useState(localStorage.getItem("vrApp"));
    const [isLoaded, setLoaded] = useState(false);
    
    useEffect(() => {
        setIsAuthenticated(localStorage.getItem("vrUser"));
        setApplicationId(localStorage.getItem("vrApp"));
        setLoaded(true);
    }, []);

    const login = (username) => {
        localStorage.setItem("vrUser", username);
        setIsAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem("vrUser");
        localStorage.removeItem("vrApp");
        setIsAuthenticated(false);
    };

    const setApplication = (appId) => {
        localStorage.setItem("vrApp", appId);
        setApplicationId(appId);
    }

    return (


        <div className="App">
            {!isLoaded ? <p>loading</p> :
                <>
                    <Router>
                        <NavMenu login={login} logout={logout} setApp={setApplication} authenticated={isAuthenticated} applicationId={applicationId} />
                        <Container>
                            <Switch>
                                {!isAuthenticated && <Route path="/login" render={() => <Login login={login}></Login>}></Route>}

                                <Route exact path="/demo/events" render={() => <DemoEvents applicationId={applicationId}></DemoEvents>}></Route>
                                <Route exact path="/demo/userflow" render={() => <DemoUserFlow applicationId={applicationId}></DemoUserFlow>}></Route>
                                <Route exact path="/login" render={() => <Login login={login}></Login>}></Route>
                                <PrivateRoute authed={isAuthenticated} exact path='/' comp={Events} applicationId={applicationId} />
                                <PrivateRoute authed={isAuthenticated} exact path='/events/:eventId/entries' comp={EventEntries} applicationId={applicationId} />
                                <PrivateRoute authed={isAuthenticated} exact path='/events/:eventId/certificates' comp={EventEntries} applicationId={applicationId} />
                                <PrivateRoute authed={isAuthenticated} exact path='/events/:eventId/edit' comp={Events} applicationId={applicationId} />
                                <PrivateRoute authed={isAuthenticated} exact path='/events/:eventId/entries/:regId' comp={EntryDetails} applicationId={applicationId} />
                                <PrivateRoute authed={isAuthenticated} exact path='/events' comp={Events} applicationId={applicationId} />
                            </Switch>
                        </Container>
                    </Router>

                </>
            }
        </div>
    );
}

export default withRouter(App);