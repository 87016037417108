
import React, { useState, useEffect } from "react";

function EventStats({ applicationId, eventId, children }) {
    const [loading, setLoading] = useState(true);
    const [eventStats, setEventStats] = useState(undefined);


    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    async function loadEventStats() {
        setLoading(true);

        const response = await (fetch(`/api/v1/admin/events/${applicationId}/${eventId}/metrics`, {}).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            setEventStats(data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadEventStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId, eventId]);


    return (
        <div className="vr-container" style={{ border: 'none', background: '#17a2b81a', padding: 8, fontFamily: 'system-ui', fontSize: 14, marginBottom: 10}}>
            {loading ? <div>Loading...</div> :
                <>
                    <div className="event-stat-row">
                        <div className="event-stat">
                            <label>Total Registrants</label>
                            <div>{eventStats.totalRegistrants.toLocaleString()}</div>
                        </div>
                        <div className="event-stat">
                            <label>Pending Registrants</label>
                            <div>{eventStats.totalRegistrantsPending.toLocaleString()}</div>
                        </div>
                        <div className="event-stat">
                            <label>Completed Registrants</label>
                            <div>{eventStats.totalRegistrantsCompleted.toLocaleString()}</div>
                        </div>
                    </div>
                    <div className="event-stat-row">
                        <div className="event-stat">
                            <label>Total Runs</label>
                            <div>{eventStats.totalLoggedRuns.toLocaleString()}</div>
                        </div>
                        
                    </div>
                    <div className="event-stat-row">
                        <div className="event-stat">
                            <label>Distance From Runs</label>
                            <div>{(eventStats.totalDistanceMetricFromRuns / 1000).toLocaleString('en-US', { maximumFractionDigits: 2 })}km</div>
                            <div style={{ color: '#6d6d6d', marginLeft: 5 }}>({eventStats.totalDistanceImperialFromRuns.toLocaleString('en-US', { maximumFractionDigits: 2 })} miles)</div>
                        </div>
                    </div>
                    <div className="event-stat-row">
                        <div className="event-stat">
                            <label>Declared Distance</label>
                            <div>{(eventStats.totalDistanceMetricDeclared / 1000).toLocaleString('en-US', { maximumFractionDigits: 2 })}km</div>
                            <div style={{ color:'#6d6d6d', marginLeft: 5}}>({eventStats.totalDistanceImperialDeclared.toLocaleString('en-US', { maximumFractionDigits: 2 })} miles)</div>
                        </div>
                        <div className="d-flex flex-row-reverse" style={{flexGrow: 1}}>
                            {children}
                            
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default EventStats;