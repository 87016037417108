
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Table, Modal, Form, Col, Row, Spinner, Image, Container, OverlayTrigger, Tooltip  } from "react-bootstrap";
import Moment from 'react-moment';
import moment from 'moment';
import EntryPlanDetails from './entryplandetails';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function EntryDetails({ applicationId, entry, handleClose, updateListings, history }) {
    const [loading, setLoading] = useState(true);
    const [editEntry, setEditEntry] = useState(undefined);
    const [showCertificate, setShowCertificate] = useState(false);
    const [enlargedEvidence, setEnlargedEvidence] = useState(undefined);
    let { eventId, regId } = useParams();

    const handleCloseCert = () => setShowCertificate(false);
    const handleShowCert = () => setShowCertificate(true);


    const onBackClick = () => {
        if (entry) {
            handleClose();
        } else {
            history.push(`/events/${eventId}/entries`);
        }
    }

    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    async function loadEntryDetail() {
        setLoading(true);

        let eId = entry ? entry.eventId : eventId;
        let rId = entry ? entry.regId : regId;

        const response = await (fetch(`/api/v1/admin/events/${applicationId}/${eId}/entries/${rId}`, {}).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            setEditEntry(data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadEntryDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry]);

    function updateEntryDetail(property, val) {
        setEditEntry(prev => ({ ...prev, [property]: val }));
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        const payload = {
            eventId: editEntry.eventId,
            regId: editEntry.regId,
            complete: editEntry.complete,
            time: editEntry.time,
            dateOfBirth: editEntry.dateOfBirth
        };

        let eId = entry ? entry.eventId : eventId;
        let rId = entry ? entry.regId : regId;

        const response = await (fetch(`/api/v1/admin/events/${applicationId}/${eId}/entries/${rId}`, {
            method: 'put',
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json" }
        }).catch((e) => { console.log(e); }));

        if (response.ok) {
            if (updateListings) { updateListings(); }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    function isDateSame(d1, d2) {
        let d1s = new Date(d1);
        let d2s = new Date(d2);
        return d1s.toLocaleDateString() === d2s.toLocaleDateString();
    }

    return (
        <div className="vr-container">
            {editEntry ?
                <>
                    <Modal size="lg" show={showCertificate} onHide={handleCloseCert}>
                        <Modal.Header closeButton>
                            <Modal.Title>Certificate</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Image src={editEntry.certificate} fluid />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseCert}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="lg" show={enlargedEvidence !== undefined} onHide={() => setEnlargedEvidence(undefined)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Evidence</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{justifyContent: 'center', display: 'flex'}}>
                            <Image src={enlargedEvidence} fluid style={{ display: 'flex', maxHeight: '70vh'}} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setEnlargedEvidence(undefined)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="title">
                        <div>Edit Entry Detail</div>
                    </div>
                    <Form>
                        <Form.Row>
                            <Col xs={6}>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>FirstName</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.firstName} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>LastName</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.lastName} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>DOB</Form.Label>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={new Date(editEntry.dateOfBirth)} onChange={(date) => { updateEntryDetail("dateOfBirth", date); }} />
                                    <OverlayTrigger
                                        key={'temp'}
                                        placement={'right'}
                                        overlay={
                                            <Tooltip id={`tooltip-right`}>
                                                External Provider DOB - <strong><Moment format="DD/MM/YYYY">{editEntry.details.dob}</Moment></strong>
                                            </Tooltip>
                                        }>
                                        <i className="fa fa-info-circle" style={{ color: isDateSame(editEntry.details.dob, editEntry.dateOfBirth) ? 'green' : 'orange', fontSize: 20, cursor: 'pointer' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Address 1</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.addressLine1} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.addressLine2} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Address 3</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.addressLine3} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Address 4</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.addressLine4} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Postcode</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.postcode} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.country} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Telephone</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.telephone} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Mobile</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.mobile} />
                                </Form.Group>

                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Complete</Form.Label>
                                    <Form.Check checked={editEntry.complete} onChange={e => updateEntryDetail('complete', e.target.checked)} />

                                    {editEntry.complete && editEntry.certificate ?
                                        <Button variant="primary" style={{ marginLeft: 20 }} onClick={handleShowCert}>
                                            View Certificate
                                        </Button> : <></>
                                    }
                                </Form.Group>
                                
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Registration Id</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.regId} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.details.email} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Finish Time</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.time} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label style={{ maxWidth: 100 }} >Distance Metric</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.distanceMetric} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label style={{ maxWidth: 100 }} >Distance Imperial</Form.Label>
                                    <Form.Control placeholder="" readOnly defaultValue={editEntry.distanceImperial} />
                                </Form.Group>
                                <Container>
                                    <Row>Evidence</Row>
                                    <Row>
                                        {
                                            editEntry.evidence.map((evidence, index) => {
                                                return (<Image key={index} style={{ maxHeight: 250, maxWidth: 250 }} src={evidence} fluid onClick={() => setEnlargedEvidence(evidence)} />)
                                            })
                                        }
                                    </Row>
                                </Container>
                            </Col>
                        </Form.Row>

                        {editEntry.event.eventTypeStr !== "plan" && editEntry.logEntries ?
                            <Form.Row style={{ marginTop: 10}}>
                                <div><b>Log Entries</b></div>
                                <Table bordered striped responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>Log Date</th>
                                            <th>Time</th>
                                            <th>Distance Metric</th>
                                            <th>Distance Imperial</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {editEntry.logEntries.map(entry =>
                                            <tr key={entry.id}>
                                                <td><Moment format="DD/MM/YYYY HH:mm">{entry.logDate}</Moment></td>
                                                <td>{entry.time}</td>
                                                <td>{entry.distanceMetric}</td>
                                                <td>{entry.distanceImperial}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Form.Row> : <></>
                        }

                        {editEntry.event.plan ? <><div><b>Event Plan</b></div><EntryPlanDetails admin={true} applicationId={applicationId} entryDetail={editEntry}></EntryPlanDetails></> : <></>}

                        <Form.Row style={{ flexDirection: "row-reverse" }}>
                            {loading ? <Spinner animation="border" variant="primary" /> :
                                <>
                                    <Button className="ml-2" onClick={handleSubmit}>
                                        Update
                                    </Button>
                                    <Button onClick={onBackClick}>
                                        Back
                                    </Button>
                                </>
                            }
                        </Form.Row>
                    </Form>
                </>
                : <></>}
        </div>
    )
}

export default EntryDetails;