
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Button, Alert, Badge } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import EntryDetails from './entrydetails';
import EventStats from './eventstats';
import EventEntriesCertificates from './evententriescertificates';

function EventEntries({ history, login, applicationId }) {
    let { eventId } = useParams();
    const [entries, setEntries] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    const [viewMode, setViewMode] = useState(0);
    const [selectedEntry, setSelectedEntry] = useState(undefined);

    const tableColumns = [
        {
            dataField: 'userId',
            text: 'ID',
            sort: true
        },
        {
            dataField: 'name',
            text: 'Name',
            formatter: nameFormatter,
            sort: true
        },
        {
            dataField: 'regId',
            text: 'Registration Id',
            sort: true
        },
        {
            dataField: 'complete',
            text: 'Status',
            formatter: statusFormatter,
            sort: true
        },
        {
            dataField: '',
            text: 'Actions',
            formatter: actionFormatter
        }
    ]
    const customPaginationTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to { to} of {size} registrants
        </span>
    );
    const paginationOptions = {
        paginationSize: 3,
        showTotal: true,
        paginationTotalRenderer: customPaginationTotal,
        sizePerPageList: [
            { text: '20', value: 20 }
        ]
    }
    const { SearchBar } = Search;

    function nameFormatter(cell, row) {
        return `${row.firstName} ${row.lastName}`;
    }

    function statusFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <Badge variant={row.complete ? "success" : "warning"}>{row.complete ? "Complete" : "Pending"}</Badge>
        );
    }

    function actionFormatter(cell, row) {
        return (
            <Button as="input" type="button" size="sm" variant="info" value="View" onClick={() => { viewEntry(row); }} />
        );
    }

    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    async function populateEntries() {
        if (!applicationId) return;
        const response = await (fetch(`/api/v1/admin/events/${applicationId}/${eventId}/entries`, {}).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            setEntries(data);
            setLoading(false);
        } else {
            setError(`No entries found for eventId - ${eventId}`);
            setLoading(false);
        }
    }

    useEffect(() => {
        populateEntries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);


    function handleClose() {
        history.push(`/events/${eventId}/entries`);
        setSelectedEntry(undefined);
    }

    function viewEntry(entry) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '/' + entry.regId;
        window.history.pushState({ path: newurl }, '', newurl);
        setSelectedEntry(entry);
    }

    function setMode(event, mode) {
        event.preventDefault();
        switch(mode) {
            case 1: 
                history.push(`/events/${eventId}/certificates`);
                setViewMode(1);
                return;
            default: 
                history.push(`/events/${eventId}/entries`);
                setViewMode(0);
                return;
        }
    }

    return (
        <div>
            {loading ? <p>Loading</p> :
                error ? <Alert variant={'danger'}>{error}</Alert> :
                    <>
                        {selectedEntry !== undefined ?
                            <EntryDetails applicationId={applicationId} entry={selectedEntry} handleClose={handleClose} updateListings={() => populateEntries()}></EntryDetails> :
                            <>
                                <EventStats applicationId={applicationId} eventId={eventId}>
                                    <Button className="ml-2" type="button" size="sm" href={`/events/${eventId}/certificates`} onClick={(e) => viewMode === 1 ? setMode(e, 0) : setMode(e, 1)}>Certificates</Button>
                                </EventStats>
                                
                                {viewMode === 0 ? 
                                    <ToolkitProvider keyField='regId'
                                        data={entries}
                                        columns={tableColumns}
                                        search={{ searchFormatted: true }}>
                                        {props => (
                                            <div>
                                                <SearchBar {...props.searchProps} placeholder="Search registrants..." />
                                                <BootstrapTable classes="table-sm" bordered striped responsive {...props.baseProps} pagination={paginationFactory(paginationOptions)}></BootstrapTable>
                                            </div>
                                        )}
                                    </ToolkitProvider> :
                                    <EventEntriesCertificates entries={entries}></EventEntriesCertificates>
                                }
                            </>
                        }
                    </>
            }
        </div>
    )
}

export default withRouter(EventEntries);