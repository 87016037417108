import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

function PrivateRoute({ comp: Component, authed, applicationId, path, ...rest }) {
    return (
        <Route
            {...rest}
            path={path}
            exact
            render={(props) => authed 
                ? <Component applicationId={applicationId} {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
        />
    )
}

export default withRouter(PrivateRoute);