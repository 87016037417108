import React, { useState } from "react";
import { Form } from "react-bootstrap";

function EventPlanSession({ session, sessionIndex, onSessionCompleted, onSessionInCompleted }) {

    function onTogglePlanSession(isChecked, session) {
        isChecked ? onSessionCompleted(session) : onSessionInCompleted(session);
    }

    return (
        <div className={`session d-flex ${session.isComplete ? 'complete' : ''}`}>
            <div className="d-flex flex-grow w-100">
                <b className="ml-2 mr-2" style={{ minWidth: 70 }}>Session {sessionIndex + 1}:</b>
                <div className="flex-grow">{session.description}</div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
                <Form.Check type="checkbox" label="" checked={session.isComplete} onChange={(ev) => { onTogglePlanSession(ev.target.checked, session); }} />
            </div>
        </div>
    )
}

function EventPlanGroup({ group, groupIndex, onSessionCompleted, onSessionInCompleted }) {
    const [hidden, setHidden] = useState(!group.hidden);

    return (
        <div className="group">
            {!hidden ?
                <>
                    <div className="group-title" onClick={() => { setHidden(!hidden) }}>
                        <div className="d-flex justify-content-between">
                            <h1>{group.title}</h1>
                            {group.isComplete ?
                                <div>
                                    <Form.Check className="vrdisabled ml-2 mr-1" type="checkbox" label="" defaultChecked={group.isComplete} />
                                </div>
                                : <></>
                            }
                        </div>

                        <h2>{group.description}</h2>
                    </div>
                    {
                        group.sessions.map((session, index) => 
                        <EventPlanSession key={session.id} session={session} sessionIndex={index} 
                                onSessionCompleted={(session) => onSessionCompleted({ group, index:groupIndex }, { session, index })} 
                                onSessionInCompleted={(session) => onSessionInCompleted({ group, index:groupIndex }, { session, index })}>
                        </EventPlanSession>)
                    }
                </> : <div className="group-title" onClick={() => { setHidden(!hidden) }}>
                        <b>{group.title}</b>  - {group.sessions.length} Session(s) {group.isComplete ? 
                        <div style={{ float: 'right' }}> <Form.Check className="vrdisabled ml-2 mr-1" type="checkbox" label="" defaultChecked={group.isComplete} /> </div> : <></>}
                        
                    </div>
            }
        </div>
    )
}

function EntryPlanDetails({ admin, applicationId, authKey, entryDetail }) {
 
    const [eventPlan, setEventPlan] = useState(entryDetail.event.plan);

    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    const updateEntryDetailSession = (groupIndex, sessionIndex, newSessionState) => {
        let currentPlan = [...eventPlan];
        let item = currentPlan[groupIndex];
        item.sessions[sessionIndex] = newSessionState;
        item.isComplete = item.sessions.every(s => s.isComplete);
        currentPlan[groupIndex] = item;
        setEventPlan(currentPlan);
    }

    const toggleSessionComplete = async (groupIndex, sessionIndex) => {
        updateEntryDetailSession(groupIndex.index, sessionIndex.index, { ...sessionIndex.session, isComplete: true });
        var payload = {
            eventId: +entryDetail.event.id,
            regId: +entryDetail.regId,
            dob: entryDetail.dateOfBirth,
            planSessionId: sessionIndex.session.id,
            logDate: new Date().toISOString()
        };
        let url = admin ? `api/v1/admin/events/${applicationId}/${payload.eventId}/entries/${payload.regId}/logs` : '/api/v1/evidence/logs';
        const response = await (fetch(url, {
            method: 'post',
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json", "x-api-key": authKey }
        }).catch(handleError));
        
        if (response.ok) {
            
        } else {
            console.log("error adding log entry");
        }

    }

    async function toggleSessionIncomplete(groupIndex, sessionIndex) {
        updateEntryDetailSession(groupIndex.index, sessionIndex.index, { ...sessionIndex.session, isComplete: false });
        let url = 
            admin ? `api/v1/admin/events/${applicationId}/${entryDetail.event.id}/entries/${entryDetail.regId}/logs?logId=${sessionIndex.session.id}` 
              : `/api/v1/evidence/logs?eventId=${entryDetail.event.id}&regId=${entryDetail.regId}&logId=${sessionIndex.session.id}`;
        const response = await (fetch(url, {
            method: 'delete',
            headers: { "Content-Type": "application/json", "x-api-key": authKey }
        }).catch(handleError));

        if (response.ok) {
        } else {
            console.log("error removing log entry");
        }
    }

    return (
        <div className="eventplan">
            <div className="d-flex flex-wrap">
                {
                    eventPlan.map((group, groupIndex) => <EventPlanGroup key={group.id} group={group} groupIndex={groupIndex} onSessionCompleted={toggleSessionComplete} onSessionInCompleted={toggleSessionIncomplete}></EventPlanGroup>)
                }
            </div>
        </div>
    )
}

export default EntryPlanDetails;