var config = {
    events: {
        eventTypes: [
            { id: 0, name: "distance" },
            { id: 1, name: "runs" },
            { id: 2, name: "plan" }
        ],
        defaultMetadata : [
            "cardimage",
            "hero",
            "step1",
            "step2",
            "step3"
        ]
    } 
};

export default config;