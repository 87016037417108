import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Table, Button } from 'react-bootstrap';
import EventEdit from './eventedit';

import globalConfig from '../../config.js';


function Events({ history, login, applicationId }) {
    let { eventId } = useParams();
    const [events, setEvents] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedEventDetail, setEventDetail] = useState(undefined);
    const eventTypes = globalConfig.events.eventTypes || [];

    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    async function populateEvents() {
        if (!applicationId) return;
        const response = await (fetch(`/api/v1/admin/events/${applicationId}`, {}).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            setEvents(data);

            if (eventId) {
                var editEvent = data.find(e => e.id === +eventId);
                if (editEvent) setEventDetail(editEvent);
            }

            setLoading(false);
        } else {
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        populateEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);

    function handleClose() {
        setEventDetail(undefined);
        history.push(`/events`);
    }

    function viewEventRegistrants(e, event) {
        e.preventDefault();
        history.push(`/events/${event.id}/entries`);
    }

    function editEvent(e, event) {
        if(e) {e.preventDefault();}
        setEventDetail(event);
        history.push(`/events/${event.id}/edit`);
    }

    function addEvent() {
        setEventDetail({});
    }

    return (
        <div>
            {loading ? <p>Loading</p> :
                error ? <p>An error occured</p> :
                    <>
                        {selectedEventDetail !== undefined ?
                            <EventEdit applicationId={applicationId} eventDetail={selectedEventDetail} handleClose={handleClose} setEventDetailInList={() => populateEvents()}></EventEdit> :
                            <>
                                <div className="d-flex flex-row-reverse mb-3">
                                    <Button className="ml-2" as="input" type="button" size="sm" value="Add Event" onClick={() => { addEvent(); }} />
                                </div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>EventId</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Active</th>
                                            <th>Evidence Entry</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {events.map(event =>
                                            <tr key={event.id}>
                                                <td>{event.id}</td>
                                                <td>{event.name}</td>
                                                <td>{eventTypes[event.eventType].name}</td>
                                                <td>{event.active ? "true" : "false"}</td>
                                                <td>{event.entryStatusActive ? "true" : "false"}</td>
                                                <td>
                                                    <Button type="button" size="sm" variant="info" href={`/events/${event.id}/entries`} onClick={(e) => { viewEventRegistrants(e, event); }}>View</Button>
                                                    <Button className="ml-2" type="button" size="sm" href={`/events/${event.id}/edit`} onClick={(e) => { editEvent(e, event); }}>Edit</Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        }
                        </>
            }
        </div>
    )
}

export default withRouter(Events);