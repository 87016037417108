
import React, { useState, useEffect } from "react";
import { Button, Form, Col, Spinner } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import globalConfig from '../../config.js';

function EventEdit({ applicationId, eventDetail, handleClose, setEventDetailInList }) {
    const [loading, setLoading] = useState(false);
    const [editEvent, setEditEvent] = useState(undefined);
    const [editEventMeta, setEditEventMeta] = useState(eventDetail.meta || {});

    const [editEventPlan, setEditEventPlan] = useState(eventDetail.plan || []);
    const [editPlanGroupIndex, setEditPlanGroupIndex] = useState(undefined);

    const [certOptions, setCertOptions] = useState([]);

    const [externalEvents, setExternalEvents] = useState([]);
    const [editEventCerts, setEditEventCerts] = useState([]);

    const [newMetaKeyName, setNewMetaKeyname] = useState("");

    const eventTypes = globalConfig.events.eventTypes || [];
    const defaultMetaKeys = globalConfig.events.defaultMetadata || [];

    const [isNewEvent, setIsNewEvent] = useState(false);
    const newEventDefaults = () => {
        return {
            active: true,
            challengeEndDate: new Date(),
            challengeStartDate: new Date(),
            entriesClosedDate: new Date(),
            entriesOpenDate: new Date(),
            entryUrl: "",
            eventCertificates: [],
            id: undefined,
            meta: {},
            name: "",
            officialName: "",
            eventType: 0,
            plan: [ {title: "Group 1", description: "Group 1 description..", Sessions: [{description: "Session 1 description.."}]} ]
        }
    }

    async function loadCertificateOptions() {
        setLoading(true);
        const response = await (fetch(`/api/v1/admin/event-certificates/${applicationId}/${eventDetail.id}`, {}).catch(e => { console.log(e); }));

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setCertOptions(data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    async function loadExternalEventOptions() {
        const response = await (fetch(`/api/v1/admin/events/nova`, {}).catch(e => { console.log(e); }));

        if (response.ok) {
            const data = await response.json();
            setExternalEvents(data);
        } else {
        }
    }

    async function loadEventDetail() {
        setLoading(true);
        const response = await (fetch(`/api/v1/admin/events/${applicationId}/${eventDetail.id}`, {}).catch(e => { console.log(e); }));

        if (response.ok) {
            const data = await response.json();
            setEditEvent(data);
            setEditEventMeta(data.meta || {});
            setEditEventCerts(data.eventCertificates);
            setEditEventPlan(data.plan || []);
            setEditPlanGroupIndex(0);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!eventDetail.id) {
            let defaultEvent = newEventDefaults();
            setEditEvent(defaultEvent);
            setEditEventMeta(defaultEvent.meta);
            setEditEventCerts(defaultEvent.eventCertificates);
            setEditEventPlan(defaultEvent.plan);
            setCertOptions([]);
            setIsNewEvent(true);
        } else {
            loadEventDetail();
            loadCertificateOptions();
        }
        loadExternalEventOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventDetail]);

    async function handleSubmit(event) {
        event.preventDefault();
        const payload = Object.assign({}, editEvent);
        payload.id = +payload.id;
        payload.eventCertificates = editEventCerts;
        payload.meta = editEventMeta;
        payload.plan = editEvent.eventType === 2 ? editEventPlan : undefined;
        setLoading(true);
        const response = await (fetch(`/api/v1/admin/events/${applicationId}`, {
            method: isNewEvent ? 'post' : 'put',
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json" }
        }).catch((e) => { console.log(e); }));

        if (response.ok) {
            setEventDetailInList();
            setIsNewEvent(false);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    function updateEventDetail(property, val) {
        setEditEvent(prev => ({ ...prev, [property]: val }));
    }

    function getMetaKeys() {
        return [...new Set([...defaultMetaKeys, ...Object.keys(editEventMeta)])];
    }

    function addMetaKey(key) {
        if (editEventMeta.hasOwnProperty(key)) return;
        updateEventMeta(key, "");
    }

    function removeMetaKey(prop) {
        const newMeta = Object.keys(editEventMeta).reduce((object, key) => {
            if (key !== prop) {
                object[key] = editEventMeta[key]
            }
            return object
        }, {})
        setEditEventMeta(newMeta);
    }

    function updateEventMeta(property, val) {
        setEditEventMeta(prev => ({ ...prev, [property]: val }));
    }

    function addPlanGroup() {
        setEditEventPlan(prev => [...prev, { title: `Group ${editEventPlan.length + 1}`, description: ''}]);
        setEditPlanGroupIndex(editEventPlan.length);
    }

    function removePlanGroup() {
        var arr = [...editEventPlan];
        arr.splice(editPlanGroupIndex, 1);
        setEditEventPlan(arr);
        setEditPlanGroupIndex(undefined);
    }

    function addPlanSession(groupIndex) {
        let currentPlan = [...editEventPlan];
        let item = currentPlan[groupIndex];
        if(!item.sessions) item.sessions = [];
        item.sessions.push({description: ''});
        currentPlan[groupIndex] = item;
        setEditEventPlan(currentPlan);
    }

    function removePlanSession(groupIndex, sessionIndex) {
        let currentPlan = [...editEventPlan];
        let currentSessions = currentPlan[groupIndex]["sessions"];
        currentSessions.splice(sessionIndex, 1);
        currentPlan[groupIndex]["sessions"] = currentSessions;
        setEditEventPlan(currentPlan);
    }

    function updatePlanGroup(groupIndex, property, val) {
        let currentPlan = [...editEventPlan];
        let item = { ...currentPlan[groupIndex], [property]: val };
        currentPlan[groupIndex] = item;
        setEditEventPlan(currentPlan);
    }

    function updatePlanSession(groupIndex, sessionIndex, property, val) {
        let currentPlan = [...editEventPlan];
        let item = currentPlan[groupIndex];
        item.sessions[sessionIndex] = { ...item.sessions[sessionIndex], [property]: val };
        currentPlan[groupIndex] = item;
        setEditEventPlan(currentPlan);
    }

    function updateCertificate(index, property, val) {
        setEditEventCerts(prev => prev.map((c, ind) => ind === index ? { ...c, [property]: val } : c));
    }

    function removeCertificate(index) {
        var arr = [...editEventCerts];
        arr.splice(index, 1);
        setEditEventCerts(arr);
    }

    function validateForm() {
        if(editEvent.eventType === 2 && editEventPlan.length <= 0)  return false;
        return editEvent && editEvent.id > 0 && editEvent.name && editEvent.name.length > 1;
    }

    return (
        <div className="vr-container mb-3">
            {editEvent ?
                <>
                    <div className="title" style={{ justifyContent: "space-between" }}>
                        <div>{isNewEvent ? 'Add Event' : 'Edit Event'}</div>
                    </div>
                    <Form className="p-3">
                        <Form.Row>
                            <Col xs={6}>
                                <Form.Group className="vr-formgroup">
                                    <Form.Label>Event Lookup</Form.Label>
                                    <Form.Control className="ml-2" as="select" disabled={!isNewEvent} custom onChange={(ev) => { 
                                            let event = externalEvents.find(p => p.id === +ev.target.value);
                                            updateEventDetail('id', event.id); 
                                            updateEventDetail('name', event.name); 
                                        }} 
                                        value={editEvent.id}>
                                        {externalEvents.map(externalEvent => (
                                            <option key={externalEvent.id} value={externalEvent.id}>
                                                ({externalEvent.id}) - {externalEvent.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="vr-formgroup">
                                    <Form.Label>Event Id</Form.Label>
                                    <Form.Control placeholder="id" disabled={!isNewEvent} value={editEvent.id || ''} onChange={e => updateEventDetail('id', e.target.value)} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control placeholder="name" value={editEvent.name} onChange={e => updateEventDetail('name', e.target.value)} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup">
                                    <Form.Label>Entry Url</Form.Label>
                                    <Form.Control placeholder="entry url" value={editEvent.entryUrl} onChange={e => updateEventDetail('entryUrl', e.target.value)} />
                                </Form.Group>
                                <Form.Group className="vr-formgroup">
                                    <Form.Label>Event Type</Form.Label>
                                    <Form.Control className="ml-2" as="select" custom onChange={(ev) => { updateEventDetail('eventType', +ev.target.value) }} value={+editEvent.eventType}>
                                        {eventTypes.map(eventType => (
                                            <option key={eventType.id} value={+eventType.id}>{eventType.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="vr-formgroup" controlId="formName">
                                    <Form.Label>Active</Form.Label>
                                    <Form.Check style={{ marginLeft: '20px !important' }} checked={editEvent.active} onChange={e => updateEventDetail('active', e.target.checked)} />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="pl-3">
                                <Form.Group>
                                    <Form.Label>Challenge Dates</Form.Label>
                                    <div>
                                        <div className="d-flex">
                                            <div className="mr-1">
                                                <span className="mr-1">From:</span>
                                                <DatePicker dateFormat="dd/MM/yyyy h:mm aa" showTimeSelect selected={new Date(editEvent.challengeStartDate)} onChange={(date) => { updateEventDetail("challengeStartDate", date); }} />
                                            </div>
                                            <div className="ml-3 mr-1">
                                                <span className="ml-3 mr-1">To:</span>
                                                <DatePicker dateFormat="dd/MM/yyyy h:mm aa" showTimeSelect selected={new Date(editEvent.challengeEndDate)} onChange={(date) => { updateEventDetail("challengeEndDate", date); }} />
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Entries Dates</Form.Label>
                                    <div className="d-flex">
                                        <div className="mr-1">
                                            <span className="mr-1">From:</span>
                                            <DatePicker dateFormat="dd/MM/yyyy h:mm aa" showTimeSelect selected={new Date(editEvent.entriesOpenDate)} onChange={(date) => { updateEventDetail("entriesOpenDate", date); }} />
                                        </div>
                                        <div className="ml-3 mr-1">
                                            <span className="ml-3 mr-1">To:</span>
                                            <DatePicker dateFormat="dd/MM/yyyy h:mm aa" showTimeSelect selected={new Date(editEvent.entriesClosedDate)} onChange={(date) => { updateEventDetail("entriesClosedDate", date); }} />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <div className="d-flex justify-content-between w-100 p-1 pb-3">
                                <h5>Certificates</h5>
                                <Button size="sm" onClick={() => setEditEventCerts(prev => [...prev, { threshold: 0, fileName: "" }])}>Add</Button>
                            </div>

                            <Col xs={12}>

                                {
                                    editEventCerts.map((cert, index) => {
                                        return (
                                            <Form.Group className="vr-formgroup" key={index}>
                                                <Form.Label>Threshold</Form.Label>
                                                <Form.Control type="number" value={cert.threshold} onChange={ev => updateCertificate(index, "threshold", +ev.target.value)} />
                                                <Form.Label style={{ textAlign: "end" }}>File</Form.Label>
                                                <Form.Control className="ml-2" as="select" custom onChange={(ev) => { updateCertificate(index, "fileName", ev.target.value) }} value={cert.fileName}>
                                                    {certOptions.map(v => (
                                                        <option key={v.fileName} value={v.fileName}>{v.fileName}</option>
                                                    ))}
                                                </Form.Control>
                                                <Button variant="danger" className="ml-2" size="sm" onClick={() => removeCertificate(index)}><i className="fa fa-trash"></i></Button>
                                            </Form.Group>
                                        )
                                    })
                                }
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <div className="d-flex justify-content-between w-100 p-1 pb-3">
                                <h5>Custom Attributes</h5>
                                <div className="d-flex">
                                    <Form.Control placeholder="enter custom field name" value={newMetaKeyName} onChange={e => setNewMetaKeyname(e.target.value)} />
                                    <Button className="ml-2" size="sm" onClick={() => addMetaKey(newMetaKeyName)}>Add</Button>
                                </div>

                            </div>
                            <Col xs={12}>

                                {
                                    getMetaKeys().map(key => {
                                        return (

                                            <Form.Group className="vr-formgroup" key={key}>
                                                <Form.Label>{key}</Form.Label>
                                                <Form.Control as="textarea" rows={1} value={editEventMeta[key] || ''} onChange={e => updateEventMeta(key, e.target.value)} />
                                                {!defaultMetaKeys.includes(key) ? <Button className="ml-2" variant="danger" size="sm" onClick={() => removeMetaKey(key)}><i className="fa fa-trash"></i></Button> : <></>}
                                            </Form.Group>
                                        )
                                    })
                                }
                            </Col>
                        </Form.Row>

                        {editEvent.eventType === 2 ?
                            <Form.Row>
                                <div className="d-flex flex-column w-100 p-1 pb-3">
                                    <h5>Event Plan</h5>
                                    <div className="d-flex w-100">
                                        <div className="flex-grow">{editEventPlan.map((grp, idx) => <Button className="mr-2" size="sm" key={idx} onClick={() => { setEditPlanGroupIndex(idx); console.log("group click", idx, grp); }}>{grp.title || idx}</Button>)}</div>
                                        <div><Button className="ml-2 mb-2" size="sm" onClick={() => addPlanGroup()}>Add</Button></div>
                                    </div>
                                    {editPlanGroupIndex !== undefined && editEventPlan[editPlanGroupIndex] !== undefined ? <div className="d-flex flex-row-reverse"><Button variant="danger" size="sm" onClick={() => {removePlanGroup();}}><i className="fa fa-trash"></i></Button></div> : <></> }
                                </div>
                                
                                    <Col xs={12} style={{minHeight: 250}}>
                                    {editPlanGroupIndex !== undefined && editEventPlan[editPlanGroupIndex] !== undefined ?
                                        <>
                                            <Form.Group className="vr-formgroup">
                                                <Form.Label>Title</Form.Label>
                                                <Form.Control as="textarea" rows={1} placeholder="group title" value={editEventPlan[editPlanGroupIndex].title} onChange={e => updatePlanGroup(editPlanGroupIndex, 'title', e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className="vr-formgroup">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="group description" value={editEventPlan[editPlanGroupIndex].description} onChange={e => updatePlanGroup(editPlanGroupIndex, 'description', e.target.value)} />
                                            </Form.Group>
                                            <div className="d-flex flex-column justify-content-between w-100 p-1 pb-3">
                                                <h5>Sessions</h5>
                                                <div className="d-flex w-100 flex-row-reverse">
                                                    <div><Button className="ml-2 mb-2" size="sm" onClick={() => addPlanSession(editPlanGroupIndex)}>Add</Button></div>
                                                </div>

                                            </div>
                                            {editEventPlan[editPlanGroupIndex].sessions ?
                                                editEventPlan[editPlanGroupIndex].sessions.map((planSession, sessionIndex) => {
                                                    return (
                                                        <Form.Group key={sessionIndex} className="vr-formgroup">
                                                            <Form.Label>{sessionIndex + 1}.</Form.Label>
                                                            <Form.Control as="textarea" rows={3} placeholder="session description text.." value={planSession.description} onChange={e => updatePlanSession(editPlanGroupIndex, sessionIndex, 'description', e.target.value)} />
                                                            <Button className="ml-3" variant="danger" size="sm" onClick={() => { removePlanSession(editPlanGroupIndex, sessionIndex); }}><i className="fa fa-trash"></i></Button>
                                                        </Form.Group>)
                                                }) : <></>
                                            }
                                        </>
                                        : <div className="d-flex align-items-center justify-content-center"><b>No session group selected.</b></div>}
                                    </Col> 

                            </Form.Row>
                            : <></>
                        }

                        <Form.Row style={{ flexDirection: "row-reverse" }} className="p-1 pb-3">
                            {loading ? <Spinner animation="border" variant="primary" /> :
                                <>
                                    <Button className="ml-2" onClick={handleSubmit} disabled={!validateForm()}>
                                        {isNewEvent ? 'Add' : 'Update'}
                                    </Button>
                                    <Button onClick={handleClose} variant="info">
                                        Back
                                    </Button>
                                </>
                            }
                        </Form.Row>
                    </Form>
                </>
                : <></>}
        </div>
    )
}

export default EventEdit;