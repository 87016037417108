
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Button, Alert, Badge, Pagination } from 'react-bootstrap';

function EventEntriesCertificates({ entries }) {
    const chunk = (array, size) =>
        array.reduce((acc, _, i) => {
            if (i % size === 0) acc.push(array.slice(i, i + size))
            return acc
        }, [])

    const [entryChunks, setEntryChunks] = useState(chunk(entries.filter(p => p.complete), 50));
    const [chunkIndex, setChunkIndex] = useState(0);

    return (
        <div>
            {entryChunks ?
                <div className="d-flex flex-wrap justify-content-center">
                    {entryChunks[chunkIndex] ?
                        entryChunks[chunkIndex].map(entry => (
                            <img key={entry.regId} style={{ width: 250, margin: 5 }} alt='' src={`https://grimages.blob.core.windows.net/virtualrun/greatrun/certificates/${entry.eventId}/${entry.regId}.png`}></img>
                        ))
                        : <></>}
                    <div className="d-flex justify-content-center align-items-center w-100">

                        {entryChunks.length === 0 ? <div className="m-3" style={{ fontSize: 20 }}>No certificates</div> :

                            <>
                                <Button type="button" size="sm" disabled={chunkIndex <= 0} onClick={() => { setChunkIndex(chunkIndex - 1); }}>
                                    Prev
                                </Button>
                                        <div className="m-3" style={{ fontSize: 20 }}>{chunkIndex + 1} / {entryChunks.length} </div>
                                        <Button type="button" size="sm" disabled={chunkIndex >= entryChunks.length - 1} onClick={() => { setChunkIndex(chunkIndex + 1); }}>
                                            Next
                                </Button>
                            </>
                        }
                    </div>
                </div>
                : <></>
            }
        </div>
    )
}

export default EventEntriesCertificates;