
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card } from "react-bootstrap";
import Moment from 'react-moment';

function DemoEvents({ applicationId }) {
    const [currentEvents, setCurrentEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    function handleError(err) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };

    async function loadEvents() {
        const response = await (fetch(`/api/v1/admin/events/${applicationId}/active-events`, {}).catch(handleError));

        if (response.ok) {
            const data = await response.json();
            setCurrentEvents(data.current);
            setUpcomingEvents(data.upcoming);
        } else {
        }
    }

    useEffect(() => {
        loadEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);


    const renderEvent = (event) => {
        return (
            <Card key={event.id} style={{ width: '18rem', marginLeft: 40 }}>
                <Card.Img variant="top" src={event.meta.cardimage || 'https://via.placeholder.com/721x223?text=virtual%20run'} />
                <Card.Body>
                    <Card.Title>{event.name}</Card.Title>
                    <Card.Text style={{ fontSize: 12 }}>
                        <div title="challenge dates">
                            <Moment format="DD/MM/YYYY HH:mm">{event.challengeStartDate}</Moment> -
                            <Moment format="DD/MM/YYYY HH:mm">{event.challengeEndDate}</Moment>
                        </div>
                        <div title="entry dates">
                            <Moment format="DD/MM/YYYY HH:mm" style={{ color: '#5d5d5d' }}>{event.entriesOpenDate}</Moment> -
                            <Moment format="DD/MM/YYYY HH:mm" style={{ color: '#5d5d5d' }}>{event.entriesClosedDate}</Moment>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }

    return (
        <div>
            <h3>Current Challenges</h3>
            <div style={{display:'flex'}}>
                {
                    currentEvents.map(e => renderEvent(e))
                }
            </div>

            <h3>Upcoming Challenges</h3>
            <div style={{ display: 'flex' }}>
                {
                    upcomingEvents.map(e => renderEvent(e))
                }
            </div>
        </div>
    )
}

export default withRouter(DemoEvents);